import { AxiosError, isAxiosError } from "axios";
import strings from '../localization'

export interface ErrorResponse {
    status: number
    data: any
}


export function parseAxiosError(error: Error | AxiosError) {
    if (isAxiosError(error)) {
        return {
            status: error.response?.status,
            data: error.response?.data
        } as ErrorResponse
    }
    return 'error';
}

export function parseErrorMessage(response: any, errorMessage?: string): string {
    var message = ''
    if (response && Array.isArray(response.data)) {
        (response.data as Array<any>).forEach(x => {
            if (x.Message) {
                if (!message) {
                    message = strings.getString(x.Message) || x.Message;
                }
                else {
                    message += '\n' + strings.getString(x.Message)|| x.Message;
                }
            }
        })
    }
    return message || errorMessage || 'An unhandled error occurred';
}