import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
 
const withRouter = (WrappedComponent: any) => (props: any) => {
  const params = useParams();
  // Readonly<[ParamsOrKey]>
  const [searchParams, setSearchParams] = useSearchParams();
  //URLSearchParams SetURLSearchParams
 
  return (
    <WrappedComponent
      {...props}
      params={params}
      searchParams={searchParams}
      setSearchParams={setSearchParams}
    />
  );
};
 
export default withRouter;