import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import Config from '../../../config';
import { parseAxiosError } from '../../../heplers/api';
import { axiosInstance } from '../../../services/https';

const PATH = 'user'
export const setUserNewPassword = createAsyncThunk('user/get/newpassword', async (data: NewPasswordRequest, thunkAPI?) => {
    try {
        var response = await axiosInstance.put(`${Config.apiUrl}/${PATH}/newpassword`, JSON.stringify(data));
        return response.data
    }
    catch (error) {
        return thunkAPI.rejectWithValue(parseAxiosError(error as (Error | AxiosError)));
    }
});