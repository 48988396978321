import { Col, Row } from "antd";

type PrivacyPolicyProps = {

}


const PrivacyPolicy = (props: PrivacyPolicyProps): JSX.Element => {


    return (
        <>
            <h2>Політика конфіденційності та захисту персональних даних</h2>
            <h3>Остання редакція: 30 березня 2023</h3>


            <Row>
                <Col>

                    <p>
                        Додаток домашньої бухгалтерії Money Manager – ідеальне рішення для тих, хто хоче легко та просто фіксувати свої доходи та контролювати витрати,
                        стежити за бюджетом і планувати подальші фінансові цілі. Наші графіки та звіти зроблять процес візуалізації ваших фінансів більш прозорим та зрозумілим
                    </p>

                    <p>
                        Цією Політикою конфіденційності та захисту персональних даних (далі – Політика) мобільного додатку домашньої бухгалтерії Money Manager
                        (далі – Сервіс) регулюється збір та обробка конфіденційної (персональної) інформації усіх осіб, які користуються Сервісом (далі – Користувач).
                    </p>

                    <p>
                        При використанні Сервісу ви довіряєте йому свою персональну та фінансову інформацію. Цінуємо це та дякуємо за довіру.
                        Конфіденційність ваших персональних даних захищено усіма сьогодні доступними технічними засобами.
                    </p>

                    <p>
                        Ваші персональні дані збираються, обробляються та використовуються для якісного використання Сервісу та це відбувається з дотриманням усіх законів України,
                        а також з урахуванням принципів та правил Регламенту General Data Protection Regulation (GDPR) Європейського Союзу.
                    </p>

                    <p>
                        Сервіс безпечно зберігає та використовує ваші дані Користувача. Сервіс не передає ваші дані третім особам. Сервіс також не збирає інформації, до обробки якої законодавством встановлені окремі вимоги
                        (відповідно до статті 7 Закону України «Про захист персональних даних»).
                    </p>

                    <p>
                        <b>Збір та використання персональних даних.</b>
                        При використанні можливостей Сервісу відбувається збір та обробка даних Користувача, зокрема:
                    </p>

                    <ul>
                        <li>
                            особисті дані, що надаються при реєстрації облікового запису Користувача, у тому числі логін і пароль доступу,
                            та подальшій авторизації під час користування Сервісом,
                            в тому числі при синхронізації з іншими Сервісами, які додатково за згодою використовує Користувач;
                        </li>

                        <li>
                            прізвище, ім’я та е-mail адреса (адреса електронної пошти) Користувача;
                        </li>

                        <li>
                            IP-адреса та часова зона, у якій перебуває мобільний пристрій Користувач Сервісу;
                        </li>

                        <li>
                            інформація про дії Користувача у Сервісі: час та тривалість користування, дії, які Користувач здійснював під час користування
                            Сервісом та будь-яка інша інформація, що може бути збережена у файлах cookies;
                        </li>

                        <li>
                            статистику відвідування Сервісу: інформацію про з’єднання, функції Сервісу, які використовує Користувач,
                            а також про дату, час, тривалість використання Сервісу;
                        </li>

                        <li>
                            інформація про пристрій: версія операційної системи, апаратна модель, IMEI та інші унікальні ідентифікатори пристрою;
                        </li>

                        <li>
                            інша інформація, яку Користувач добровільно повідомив Сервісу, з дотриманням усіх вимог законодавства України.
                        </li>
                    </ul>

                    <p>
                        Використання Користувачем як суб’єктом персональних даних можливостей Сервісу та добровільне надання персональних даних відповідно до вимог
                        законодавства є згодою суб’єкта персональних даних на надання та обробку своїх персональних даних.
                    </p>

                    <p>
                        Сервіс збирає тільки таку інформацію Користувача, яка спрощує та робить більш ефективною його роботу, у тому числі щодо:
                    </p>

                    <ul>
                        <li>
                            пропозицій персоналізованого (інформаційного та рекламного) контенту та рекомендацій щодо користування Сервісом;
                        </li>

                        <li>
                            для оцінки та аналізу ринку, клієнтів, продуктів та відповідних досліджень, що надаються через Сервіс, що включає проведення опитувань Користувача щодо Сервісу;
                        </li>

                        <li>
                            забезпечення оновлення та технічної підтримки Сервісу на мобільному пристрої Користувача;
                        </li>

                        <li>
                            забезпечення оновлення та технічної підтримки Сервісу на мобільному пристрої Користувача;
                        </li>
                    </ul>

                    <p>
                        Персональні дані зберігаються на термін не більше, ніж це необхідно відповідно до мети їх обробки.
                        Після того, як Користувач припинив користуватися Сервісом та видалив свій обліковий запис, його персональні дані також автоматично видаляються.
                    </p>

                    <p>
                        Сервіс залишає за собою право зміни умов Політики, у тому числі у випадку зміни вимог законодавства. Якщо такі зміни до Політики відбудуться, Користувач отримує відповідне повідомлення у Сервісі, де буде зазначено термін набрання цими змінами чинності. Якщо після відповідного сповіщення Користувач продовжуватиме використовувати Сервіс, це означатиме його згоду на відповідні зміни та подальше використання Сервісу на зазначених умовах.
                    </p>

                </Col>
            </Row>
        </>

    )
}

export default PrivacyPolicy;



