import { PureComponent, ReactNode, useEffect, useState } from "react";
import { connect } from 'react-redux';
import LoadingIcons from 'react-loading-icons'
import { getUserConfirmation } from '../../redux/reducers/userConfirmation/api'
import { setUserNewPassword } from '../../redux/reducers/user/api'
// @ts-ignore
import { NotificationContainer, NotificationManager } from 'react-notifications';
import './index.css'
import withRouter from "../withRouter";
import { Button, Col, Form, Input, Row, Spin, notification } from "antd";

interface SetPasswordProps {
    params?: any;
    user?: UserState
    userConfirmation?: UserConfirmationState
    location?: any
    searchParams?: any

    getUserConfirmation?: (guid: string) => Promise<any>
    setUserNewPassword?: (data: NewPasswordRequest) => Promise<any>
}


const SetPassword: React.FC<SetPasswordProps> = (props) => {

    const [loading, setLoading] = useState(false);
    const [guid, setGuid] = useState('');
    const [isInvalidLink, setIsInvalidLink] = useState(false);
    const [isPasswordSet, setIsPasswordSet] = useState(false);

    const [api, contextHolder] = notification.useNotification();
    
    useEffect(() => {
        const guid = props.searchParams.get('g');
        setGuid(guid);
        async function effect() {
            if (props.getUserConfirmation) {
                let response = await props.getUserConfirmation(guid);
                if (!!response.error) {
                    setIsInvalidLink(true);
                }
            }
        }

        effect()
    }, [])

    const onFinish = async (values: any) => {
        console.log(values)
        if (props.setUserNewPassword) {
            await props.setUserNewPassword({
                guid: guid,
                password: values.password,
                confirmedPassword: values.confirmedPassword
            });
            if (props.user?.error) {
                openNotificationWithIcon(props.user?.error)
            }
            else {
                setIsPasswordSet(true)
            }
        }
        setLoading(false)
    };

    const onFinishFailed = (errorInfo: any) => {
        setLoading(false)
    };

    const openNotificationWithIcon = (description: string) => {
        api['error']({
            message: 'От халепа',
            description: description,
            placement: 'bottomRight'
        });
    };

    return (
        <Row justify='center'>
            {contextHolder}
            <Col span={8}>
                <Spin spinning={props.userConfirmation?.loading == 'pending' || props.user?.loading == 'pending'}>
                    {isInvalidLink && <text> Недійсне посилання підтвердження зміни пароля</text>}
                    {isPasswordSet && <text> Пароль успішно змінено</text>}
                    {!isInvalidLink && !isPasswordSet && (<Form
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        layout="vertical">
                        <Form.Item
                            label="Пароль"
                            name="password"
                            rules={[
                                { required: true, message: 'Пароль обов’язковий' },
                                { min: 4, message: 'Пароль повинен бути більший ніж 3 символи' },
                                { max: 100, message: 'Пароль не повинний перевищувати 100 символів' },
                            ]}
                        >
                            <Input.Password disabled={loading} />
                        </Form.Item>

                        <Form.Item
                            label="Повторіть пароль"
                            name="confirmedPassword"
                            dependencies={['password']}
                            rules={[
                                { required: true, message: 'Пароль обов’язковий' },
                                { min: 4, message: 'Пароль повинен бути більший ніж 3 символи' },
                                { max: 100, message: 'Пароль не повинний перевищувати 100 символів' },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Паролі не співпадають'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password disabled={loading} />
                        </Form.Item>

                        <Form.Item >
                            <Button htmlType="submit" type="primary" loading={loading} onClick={() => setLoading(true)}>Продовжити</Button>
                        </Form.Item>
                    </Form>)}
                </Spin>
            </Col>
        </Row>
    )
}


const mapStateToProps = (state: any) => ({
    user: state.user,
    userConfirmation: state.userConfirmation,
});
const mapDispatchToProps = () => ({
    getUserConfirmation,
    setUserNewPassword,
});
export default connect<any, any>(
    mapStateToProps,
    mapDispatchToProps()
)(withRouter(SetPassword));