import React from "react";
import { createRoot } from "react-dom/client";
import { Button, Col, DatePicker, Layout, Menu, MenuProps, Row, Space, theme, version } from "antd";
import 'antd/dist/reset.css';
import { Content, Footer, Header } from "antd/es/layout/layout";
import { LaptopOutlined, NotificationOutlined, UserOutlined } from '@ant-design/icons';
import { Route, Routes, useNavigate } from "react-router";
import PrivacyPolicy from "../privacyPolicy";
import Home from "../home";
import NotFound from "../notFound";
import SetPassword from "../setPassword";
import DeleteDataRequest from "../deleteDataRequest";
//import "./index.css";

const items1: MenuProps['items'] = [
  { key: '/', label: 'Головна' },
  // { key: '/privacyPolicy', label: 'Політика конфіденційності' },
]


const App = () => {
  const {
    token: { colorBgContainer, colorText },
  } = theme.useToken();
  const navigate = useNavigate();

  return (
    <Layout>
      <Header className="header" style={{ padding: 0, backgroundColor: '#fff' }} >
        <div style={{ maxWidth: 1200, margin: 'auto' }}>
          <div className="logo" />
          <Menu theme='light' mode="horizontal" items={items1} onClick={({ key }) => { navigate(key) }} />
        </div>


      </Header>
      <Row align={"middle"}>
        <Content style={{ padding: '0', maxWidth: 1200, margin: 'auto', display: 'block' }}>

          <Layout style={{ padding: '24px 0', background: colorBgContainer }}>
            <Content style={{ padding: '0 24px', minHeight: 280 }}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/setpas" element={
                      <React.Suspense fallback={<>...</>}>
                        <SetPassword />
                      </React.Suspense>
                    } />
                <Route path="/deleteDataRequest" element={<DeleteDataRequest />} />
                <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
                <Route path='*' element={<NotFound />} />
              </Routes>
            </Content>
            {/* <Layout.Sider style={{ background: colorBgContainer }} breakpoint="lg" collapsedWidth={0}>
           test
          </Layout.Sider> */}
          </Layout>
        </Content></Row>
      {/* <Footer style={{ textAlign: 'center' }}>Money Manager ©2023</Footer> */}
      <Footer style={{padding: 0}}>
        <div style={{ maxWidth: 1200, margin: 'auto' }}>
          <Row>
            <Col span={12}>
              <Button type="link" style={{ color: colorText }} href="https://apps.apple.com/app/apple-store/id1666043372?pt=125995746&ct=Web%20Site&mt=8">
                iPhone
              </Button>
              <Button type="link" style={{ color: colorText }} href="https://play.google.com/store/apps/details?id=com.sierra.moneymanager&utm_source=Web%20Site&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                Android
              </Button>
            </Col>
            <Col span={12}>
              <Row justify="end">
                <Col>
                  <Button type="link" style={{color: colorText}} href="/deleteDataRequest">
                    Запит на видалення даних
                  </Button>
                  <Button type="link" style={{color: colorText}} href="/privacyPolicy">
                    Конфіденційність
                  </Button>
                  <Button type="link" style={{color: colorText}} href="mailto:support@moneymanager.com.ua">
                    Підтримка
                  </Button>
                  
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Footer>
    </Layout>
  );
};


export default App;