import { PureComponent, ReactNode } from "react";
import './index.css'

type NotFoundProps ={

}


const NotFound = (props: NotFoundProps): JSX.Element => {


    return (
        <div className="wrap">
            <div>
                <h1>404</h1>
            </div>
            <div>
                <h2>Сторінку не знайдено</h2>
            </div>
        </div>
    )
}



export default NotFound;



