import { createSlice } from '@reduxjs/toolkit'
import { parseErrorMessage } from '../../../heplers/api';
import { getUserConfirmation } from './api';

const initialState: UserConfirmationState = {
    loading: 'idle',
    error: '',
  };

export const userConfirmationSlice = createSlice({
  name: 'userConfirmation',
  initialState: initialState,
  reducers: {
    increment: (state) => {
      //state.value += 1
    },
    decrement: (state) => {
      //state.value -= 1
    },
    incrementByAmount: (state, action) => {
      //state.value += action.payload
    },
  },
  
  extraReducers: (builder) => {
    // GET
    builder.addCase(getUserConfirmation.pending, (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.error = '';
      }
    })
    builder.addCase(getUserConfirmation.fulfilled, (state, action) => {
      if (state.loading === 'pending') {
        state.item = action.payload
        state.loading = 'idle'
      }
    })
    builder.addCase(getUserConfirmation.rejected, (state, action) => {
      if (state.loading === 'pending') {
        console.log(action.payload)
        state.loading = 'idle';
        state.error = parseErrorMessage(action.payload, 'Щось пішло не так');
      }
    })
}
})

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount } = userConfirmationSlice.actions

export default userConfirmationSlice.reducer