import axios, { AxiosError, AxiosResponse, isAxiosError } from 'axios';
import Config from '../config'
import { parseAxiosError } from '../heplers/api'

export const axiosInstance = axios.create({
    baseURL: Config.apiUrl,
});

axiosInstance.interceptors.request.use(async (config: any) => {

    config.headers = {
        //appVersion: '1.0.0',
        'Content-Type': 'application/json'
    }

    return config;
}, (error: any) => {
    // Do something with request error
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use((response: AxiosResponse) => {
    return response;
}, (error: any) => {
    return Promise.reject(error);
});

