import { Col, Row } from "antd";

const DeleteDataRequest = (): JSX.Element => {


    return (
        <>
            <h2>Запит на видалення даних</h2>
            <Row>
                <Col>
                    <ul>
                        <li>Відкрийте додаток</li>
                        <li>Перейдіть в 'Меню'</li>
                        <li>Перейдіть в 'Користувач'</li>
                        <li>Натисніть 'Видалити'</li>
                        <li>Введіть 'Email' та 'Пароль'</li>
                        <li>Натисніть 'Видалити'</li>
                    </ul>
                </Col>
            </Row>
        </>
    )
}

export default DeleteDataRequest;



