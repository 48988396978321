
import AppStore from '../../assets/icons/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg'
import { Col, Row } from 'antd';



const Home = (): JSX.Element => {


    return (

        <>
            <h2>Вітаємо</h2>
            <Row>
                <Col>

                    <p>
                        Money Manager - це ідеальне рішення для тих, хто шукає простий та зручний спосіб вести облік своїх фінансів.
                        Завдяки його інтуїтивно зрозумілому інтерфейсу та вбудованим функціям, ви зможете легко контролювати свої витрати та доходи,
                        стежити за бюджетом і планувати фінансові цілі.
                    </p>

                    <p>
                        Money Manager - дозволяє створювати різні категорії витрат і доходів, додавати транзакції, переглядати статистику за місяць,
                        тиждень, день або період. Наші графіки та звіти зроблять процес візуалізації витрат та доходів більш прозорим та зрозумілим.
                    </p>

                    <p>
                        Зберігайте дані в безпеці - Money Manager має високий рівень захисту, що дозволяє захистити ваши дані від несанкціонованого доступу.
                    </p>

                    <p>
                        Використання нашого додатку дозволить вам контролювати свої фінанси та планувати майбутнє, що допоможе досягти ваших фінансових цілей.
                        Завантажуйте наш додаток вже сьогодні та почніть зберігати контроль над своїми фінансами!
                    </p>

                    <Row align="middle" justify='center'>

                        <Col span={12}>
                            <Row align="middle" justify="center">
                                <Col>
                                    <a href="https://apps.apple.com/app/apple-store/id1666043372?pt=125995746&ct=Web%20Site&mt=8">
                                        <img src={AppStore} alt="Download on the App Store Badge" />
                                    </a>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Row align="middle" justify="center">
                                <Col>
                                    <a href='https://play.google.com/store/apps/details?id=com.sierra.moneymanager&utm_source=Web%20Site&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                                        <img alt='Get it on Google Play' height={59} src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' />
                                    </a>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
        /*<Card>
            <Card.Header>
                <Card.Title>Вітаємо</Card.Title>
            </Card.Header>

            <Card.Body className="pt-0">
                <Row>
                    <Col className="">

                        <p>
                            Money Manager - це ідеальне рішення для тих, хто шукає простий та зручний спосіб вести облік своїх фінансів.
                            Завдяки його інтуїтивно зрозумілому інтерфейсу та вбудованим функціям, ви зможете легко контролювати свої витрати та доходи,
                            стежити за бюджетом і планувати фінансові цілі.
                        </p>

                        <p>
                            Money Manager - дозволяє створювати різні категорії витрат і доходів, додавати транзакції, переглядати статистику за місяць,
                            тиждень, день або період. Наші графіки та звіти зроблять процес візуалізації витрат та доходів більш прозорим та зрозумілим.
                        </p>

                        <p>
                            Зберігайте дані в безпеці - Money Manager має високий рівень захисту, що дозволяє захистити ваши дані від несанкціонованого доступу.
                        </p>

                        <p>
                            Використання нашого додатку дозволить вам контролювати свої фінанси та планувати майбутнє, що допоможе досягти ваших фінансових цілей.
                            Завантажуйте наш додаток вже сьогодні та почніть зберігати контроль над своїми фінансами!
                        </p>

                        <Col className="d-flex justify-content-center">
                            <a href="https://apps.apple.com/us/app/money-manager/id1666043372">
                                <img src={AppStore} alt="Download on the App Store Badge"/>
                            </a>
                        </Col>

                    </Col>
                </Row>
            </Card.Body>
        </Card>*/
    )
}

export default Home;



