import { createSlice } from '@reduxjs/toolkit'
import { parseErrorMessage } from '../../../heplers/api';
import { setUserNewPassword } from './api';

const initialState: UserState = {
    loading: 'idle',
    error: '',
  };

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    increment: (state) => {
      //state.value += 1
    },
    decrement: (state) => {
      //state.value -= 1
    },
    incrementByAmount: (state, action) => {
      //state.value += action.payload
    },
  },
  
  extraReducers: (builder) => {
    // TOKEN
    builder.addCase(setUserNewPassword.pending, (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.error = '';
      }
    })
    builder.addCase(setUserNewPassword.fulfilled, (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
      }
    })
    builder.addCase(setUserNewPassword.rejected, (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle';
        state.error = parseErrorMessage(action.payload, 'Щось пішло не так');
      }
    })
}
})

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount } = userSlice.actions

export default userSlice.reducer