import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import store from './redux/store'
import { Provider } from 'react-redux'
import reportWebVitals from './reportWebVitals';
import App from './components/app';
import  './index.css';
import { ConfigProvider } from 'antd';

ConfigProvider.config({
  theme: {

  }
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <ConfigProvider >
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ConfigProvider>
  </Provider>

)

reportWebVitals();
