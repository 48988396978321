import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import Config from '../../../config';
import { parseAxiosError } from '../../../heplers/api';
import { axiosInstance } from '../../../services/https';

const PATH = 'userConfirmation'
export const getUserConfirmation = createAsyncThunk('userConfirmation/get', async (g?: string, thunkAPI?) => {
    try {
        var response = await axiosInstance.get(`${Config.apiUrl}/${PATH}/${g}`);
        return response.data
    }
    catch (error) {
        return thunkAPI.rejectWithValue(parseAxiosError(error as (Error | AxiosError)));
    }
});